.closeButton {
   color: inherit;
   padding: 0;
   background-color: transparent;
   border: none;
   -webkit-appearance: none;
   margin-left: auto;
   display: flex;
   cursor: pointer;

   svg {
      height: 16px;
   }
}

.header {
   :global .modal-title {
      display: flex;
      align-items: center;
      flex: 1;
      font-size: 1.5rem;
      font-weight: 600;
   }
}

.logo {
   height: 2rem;
}
