@import './globals.module';

.wrapper {
   overflow: auto;
   display: flex;
}

.suggestions {
   display: flex;
   position: relative;

   .suggestion {
      background: white;
      border-color: #d1d1d1;
      display: flex;
      flex-flow: column;
      min-width: 10rem;
      align-items: flex-start;
      text-align: left;
      white-space: nowrap;

      &.active,
      &:active {
         background: rgba($accent-light, 0.15);
      }

      &:hover,
      &:focus,
      &.active {
         border-color: $accent-light;
      }

      & + .suggestion {
         margin-left: 0.5rem;
      }

      :global(.fa) {
         margin-right: 0.25rem;
      }

      .available {
         font-weight: normal;

         :global(.fa) {
            color: $green;
         }
      }

      .unavailable {
         font-weight: normal;
         :global(.fa) {
            color: $red;
         }
      }
   }

   .loading {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0.5;
      background: url('./loading.svg') white;
      display: flex;
      align-items: center;
      padding: 0.5rem;
      min-width: 25rem;
      min-height: 3rem;
   }
}
