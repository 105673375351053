@import '../globals.module';

.page {
   height: 100vh;
   overflow-y: auto;

   h2 {
      padding: 1rem;
      margin: 0;
      font-size: 1.2rem;
      font-weight: normal;
   }
}

.button {
   margin: 2rem auto 0;
   padding: 1rem 2rem;
}

.meetings {
   padding: 1rem;

   & + & {
      border-top: 1px solid #efefef;
      // border-bottom: 1px solid #efefef;
   }

   ul {
      padding: 0;
      margin: 0;
      list-style: none;
   }
}

.title {
   font-weight: 600;
}

.notify {
   margin: 0.5rem 0;
}
