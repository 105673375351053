.sideSheet {
   background: white;
   position: fixed;
   left: 0;
   top: 0;
   bottom: 0;
   right: 0;
   margin: 0;
   z-index: 1;

   box-shadow: 0 0 4px #00000088;
}

.enter {
   transform: translateX(20%);
   opacity: 0;
}

.enterActive {
   transform: translateX(0);
   opacity: 1;
   transition: all 150ms ease-out;
}

.exit {
   opacity: 1;
   transform: translateX(0);
}

.exitActive {
   transform: translateX(100%);
   opacity: 0;
   transition: all 150ms ease-out;
}
