@import './globals.module';

.pieButton {
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;

   border: none;
   background: none;
   outline: none;
   padding: 0;

   color: inherit;
   font-weight: inherit;
   font-size: inherit;
   cursor: pointer;
   color: inherit;

   &:focus {
      outline: none;
   }

   div {
      position: absolute;
      display: flex;
      flex-flow: column;
      align-items: center;
   }

   svg {
      flex: 0 0 100%;
      transform: rotate(-90deg);
   }

   circle {
      fill: transparent;
   }

   .value {
      stroke: $green;
   }

   .value2 {
      stroke: darken($green, 10%);
   }

   .background {
      stroke: white;
   }
}
