@import './globals.module';

@mixin button() {
   color: inherit;
   border: 2px solid currentColor;
   border-radius: 3px;
   min-width: 7rem;
   background: none;
   cursor: pointer;

   font-size: 1.25rem;
   min-height: 2.5rem;
}

.booking {
   flex: 1 0 auto;

   display: flex;
   flex-flow: column;
   background-color: $background;

   .header {
      display: flex;
      background: $accent;
      color: $accentedForeground;
      align-items: center;
      padding: 1rem 1.5rem;

      line-height: 1rem;
      font-size: 1.5rem;
      font-weight: 600;

      > * {
         z-index: 1;
      }
   }

   &.teamsMode .header {
      background: #ddd;
      color: #444;
      font-size: 1.2rem;

      .backButton {
         color: $accent;
      }

      .mainButton {
         background: $accent;
         border-color: $accent;
         color: white;
      }
   }
}

.mainButton {
   @include button();
   margin-left: auto;
}

.scheduleContainer {
   overflow-y: auto;
   flex: 1 0 auto;
   padding: 0.5rem 0;

   > * {
      height: 0;
   }
}

.headerButton {
   border: none;
   background: none;
   cursor: pointer;
   padding: 0;

   display: flex;
   flex-flow: column;
   align-items: center;

   font-size: 0.8rem;
   min-width: 5rem;
   margin: -1rem 0;

   svg {
      height: 2rem;
   }

   margin-left: auto;
   + .headerButton {
      margin-left: 0;
   }
}

.date {
   position: absolute;
   left: 50%;
   transform: translate(-50%, 0);
   white-space: nowrap;
   z-index: 0;
}

.backButton {
   @include button();
   margin-right: 1rem;
}
