@import '../globals.module';

$left-gap: 4rem;
$margin-x: 1.5rem;

.input {
   display: flex;
   align-items: center;
   padding: 0.5rem $margin-x;

   &.withIcon {
      padding-left: $left-gap;
   }

   > input,
   > textarea,
   > select {
      border: none;
      background: none;
      border-bottom: solid 1px #666;
      flex: 1;
      padding: 0.25rem 0 0;
      margin-bottom: 0.25rem;
      outline: none;

      &:focus {
         margin-bottom: calc(0.25rem - 1px);
         border-bottom: solid 2px $accent;
      }
   }

   > label {
      display: flex;
      flex: 1;
      align-items: center;
   }
}

.icon {
   margin-left: -$left-gap;
   width: $left-gap;
   padding-left: $margin-x;
   color: #666;
}

.addon {
   margin-left: auto;
}
