.FloorMap {
   display: flex;
   flex-flow: column;
}

.header {
   font-size: 3rem;
   font-weight: 300;
}

.map {
   flex: 1;

   svg {
      fill: none;
   }
}

@keyframes flash {
   from {
      opacity: 0;
   }

   to {
      opacity: 1;
   }
}

.free {
   fill: #88f688aa;
}

.busy {
   fill: #f68888aa;
}

.flash {
   fill: #88f688aa;
   animation: flash 0.5s ease-out infinite alternate;
}
