@import './globals.module';

$size: 30px;

.free {
   color: $green;
}

.busy {
   color: $red;
}

.photo {
   border-radius: 50%;
   width: $size;
   height: $size;
   min-width: $size;
   object-fit: cover;
   box-sizing: content-box;
}

.icon {
   padding: 3px;
   width: $size;
   height: $size;
}

.error {
   width: $size;
   height: $size;
   box-sizing: content-box;
   border-radius: 50%;

   svg {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      font-size: $size * 0.4;
   }
}
