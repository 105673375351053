.shade {
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   margin: 0;
   background: #00000088;

   display: flex;
   flex-flow: column;
   justify-content: flex-end;

   z-index: 9;
}

.sheet {
   background: white;
   padding: 0.5rem;
   display: flex;
   flex-flow: column;

   border-top-left-radius: 8px;
   border-top-right-radius: 8px;
}

.enter {
   opacity: 0;

   .sheet {
      transform: translateY(50%);
   }
}

.enterActive {
   opacity: 1;
   transition: opacity 150ms ease-out;

   .sheet {
      transition: transform 150ms ease-out;
      transform: translateY(0);
   }
}

.exit {
   .sheet {
      opacity: 1;
      transform: translateY(0);
   }
}

.exitActive {
   opacity: 0;
   transition: opacity 150ms ease-out;

   .sheet {
      transition: transform 150ms ease-out;
      transform: translateY(50%);
   }
}
