@import './globals.module.scss';

.svg {
   color: $accent;
   height: 15rem;
}

.body {
   display: flex;
   flex-flow: column;
   align-items: center;

   button {
      margin-top: 1rem;
      min-width: 7rem;
   }
}

.error {
   color: $red;
   align-self: stretch;
   font-weight: 600;
   white-space: pre-line;
}

.curtain {
   position: fixed;
   left: 0;
   top: 0;
   bottom: 0;
   right: 0;
   z-index: 9999999;
}

.mobile {
   position: fixed;
   left: 0;
   top: 0;
   bottom: 0;
   right: 0;
   z-index: 9999999;

   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
   background: #ffffffdd;

   div {
      margin: 1rem;
      text-align: center;
   }

   svg {
      width: 20vmin;
      fill: $accent;
   }
}
