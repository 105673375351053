@import './globals.module';

.body {
   background: white;
   font-size: 1.2rem;
}

.logoutButton {
   margin-left: 1rem;
   background: none;
   border-width: 2px;
   color: inherit;
}

.em {
   color: $accent;
   font-size: 1.5rem;
}
