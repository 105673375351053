@import '../globals.module';

.meetingTypeSelector {
   font-size: 0.8rem;
   white-space: nowrap;
}

.meetingTypeButton {
   min-width: 4rem;
}

.roomTypeButton {
   min-width: 4rem;
   margin-right: 0.5rem;
}

.meetingType {
   display: flex;
   align-items: center;

   .meetingTypeIcon {
      margin-right: 4px;
   }
}

.spacer {
   padding-right: 2rem;
   margin-right: 2rem;
}

.meetingTypeIcon {
   width: 24px;
   height: 18px;

   fill: $accent;
}
