@import '../globals.module';

@mixin button {
   border: none;
   background: none;
   padding: 0;

   &:focus {
      outline: none;
   }
}

.attendeeList {
   display: flex;
   flex-flow: row wrap;
   margin: 0.75rem 0 0.25rem;
   align-items: center;
}

.attendeeCard {
   @include button;
   display: grid;
   grid-template: 1fr auto / auto 1fr;

   align-items: center;
   border-radius: 6px;
   background: #f4f4f4;
   margin-right: 0.5rem;
   margin-bottom: 0.5rem;
   font-size: 0.9rem;
   text-align: left;

   .photo {
      margin-right: 0.5rem;
      grid-area: 1 / 1 / 3 / 2;
      height: 30px;
      width: 30px;
   }

   .name {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 0.5rem;
      line-height: 1;
      overflow: visible;
      color: black;

      &:last-child {
         grid-area: 1 / 2 / 3 / 3;
      }
   }

   .status {
      font-size: 0.8rem;
      overflow: visible;
      margin-right: 0.5rem;
      line-height: 1;
      padding-bottom: 2px;

      .type {
         color: #666;
      }

      .free {
         color: $green;
      }

      .busy {
         color: $red;
      }
   }
}

.attendeeSelector {
   @include button;
   margin: 0;
}

.list {
   padding: 0 0.5rem;
   border-bottom: 1px solid #eee;
   margin-bottom: 0;
   padding-bottom: 0.25rem;
}

.menuButton {
   @include button;
   padding: 0.5rem 1rem;
   font-weight: 600;
}

.delete {
   color: red;
}

.input {
   flex: 1;
   margin-right: 0.5rem;
   margin-bottom: 0.5rem;
   min-width: 7rem;
}

.results {
   display: flex;
   flex-flow: column;
   padding: 0 1rem;
   min-height: 0;
   flex: 1;
   overflow: auto;
}

.result {
   @include button;
   display: grid;
   grid-template: auto auto / auto 1fr;
   text-align: left;

   .photo {
      grid-area: 1 / 1 / 3 / 2;
      align-self: center;
      margin-right: 1rem;
   }

   .name {
      line-height: 1;
      padding-top: 0.75rem;
   }

   .email {
      line-height: 1.27;
      color: #666;
      border-bottom: 1px solid #eee;
      padding-bottom: 0.75rem;
      font-size: 0.9rem;
   }
}
