@import './globals.module';

div.modal {
   max-width: 600px;
}

.agendaEditor {
   display: flex;
   flex-flow: column;
   background: white;

   > label {
      font-weight: 600;
   }

   .text {
      height: 8rem;
      resize: vertical;
      margin-bottom: 1rem;
      background: var(--controlBackground);
      border: none;
      border-radius: 3px;
   }

   .items {
      flex: 1;
      display: flex;
      flex-flow: column;
      margin-bottom: 1rem;
   }

   .item {
      display: flex;
      align-items: center;
      $padding-x: 0.5rem;
      padding: 0.25rem $padding-x;
      cursor: pointer;

      > * + * {
         margin-left: $padding-x;
      }

      &:hover {
         background: transparentize($accent, 0.75);
      }

      .subject {
         font-size: 1.25rem;
         font-weight: 300;
         flex: 1;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
      }

      .time {
         font-weight: 600;
      }

      .icon {
         display: flex;
         width: 16px;
         align-items: center;
         justify-content: center;
         color: #444;

         svg {
            width: 16px;
         }
      }

      .edit {
         background: none;
         border: none;
         padding: 0;
         cursor: pointer;
         width: 32px;
         color: #444;
         display: flex;
         justify-content: center;

         svg {
            width: 24px;
         }
      }
   }

   .total {
      display: flex;
      align-items: center;
      font-size: 1.25rem;
      font-weight: 300;

      span {
         margin: 0 0.25rem;
         font-weight: 600;

         &:last-child {
            margin-right: 0;
         }

         &.error {
            color: $red;
         }
      }
   }

   .add {
      margin-right: auto;
      font-size: 1rem;
   }

   .editScope {
      user-select: none;
      margin: 2rem 0 0;
      display: flex;
      align-items: center;

      > * {
         margin-right: 0.5rem;
      }
   }

   .buttons {
      display: flex;
      align-items: center;
      margin-top: 2rem;

      .save {
         height: 3rem;
         min-width: 8rem;
      }

      .cancel {
         height: 3rem;
         min-width: 8rem;
         margin-left: 0.5rem;
      }
   }
}

.itemEditor {
   display: flex;
   flex-flow: column;
   background: white;
   padding: 1.5rem;

   label {
      font-weight: 600;
   }

   input {
      background: var(--controlBackground);
      border: none;
      border-radius: 3px;
      height: 35px;
      padding: 0 0.5rem;
   }

   .subject {
      flex: 1;
   }

   .time {
      align-items: flex-end;

      label {
         margin: 0;
         margin-right: auto;
      }

      input {
         max-width: 6.25rem;
      }

      .button {
         margin-left: 0.5rem;
         min-width: 3.5rem;
      }
   }

   .link {
      flex: 1;
   }

   > section {
      display: flex;
      margin-bottom: 1rem;

      &.disabled {
         opacity: 0.5;
      }

      > label {
         display: flex;
         flex-flow: column;

         > div {
            padding: 0;
         }
      }
   }

   .or {
      font-size: 1.25rem;
      text-align: center;
      margin-bottom: 1rem;
   }

   .drop {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      height: 15.25rem;

      border-style: solid;
      border-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAGXRFWHRTb2Z0d2FyZQBwYWludC5uZXQgNC4wLjE2RGmv9QAAADVJREFUeNpjmDJn6X9sGAgYYHjq3GVY1TAMDs3IGnDhQaKZGA248ABqHqKhTUwKw6Vm4DQDAHr2jzn27RcCAAAAAElFTkSuQmCC')
         1.5 / 2px repeat;
      background: var(--controlBackground);
      color: #4f5962;
      font-weight: 600;
      font-size: 1.1rem;

      :global(.fa) {
         margin-right: 0.25rem;
      }

      .size {
         font-size: 1rem;
      }

      button {
         margin-top: 1rem;
         font-size: 1rem;
      }

      p {
         margin: 0.25rem 0 0;
      }
   }

   .buttons {
      display: flex;
      align-items: center;
      margin-top: 2rem;

      > * {
         height: 3rem;
         min-width: 8rem;
         margin-right: 0.5rem;
      }

      .delete {
         margin-right: 0;
         margin-left: auto;
      }
   }
}
