.timeAndDate {
   display: flex;
   flex-flow: column;
   align-items: center;

   .time {
      font-size: 4rem;
      line-height: 1;
   }

   .date {
      font-size: 1.5rem;
   }
}
