@import '../globals.module';
$green: #3cb878;

@mixin ellipsis() {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.RoomLine {
   background: #ffffff66;
   margin-bottom: 2px;
   border-left: 1rem solid $green;
   padding: 0.25rem 0.5rem;
   font-weight: 600;

   display: grid;
   grid-template: 1fr 1fr / 3fr auto 4fr 2rem 3.5rem;
   align-items: center;

   &.busy {
      border-left-color: $red;
      .till {
         color: #ae0000;
      }
   }

   .roomName {
      font-size: 1.25rem;
      @include ellipsis();
   }

   .till {
      color: $green;
      grid-area: 2 / 1 / 3 / 2;
   }

   .location {
      grid-area: 1 / 2 / 3 / 3;
      padding: 0 1rem;

      svg {
         height: 3rem;
         width: 3rem;
      }
   }

   .subject {
      font-size: 1.25rem;
      @include ellipsis();
      grid-area: 1 / 3 / 2 / 4;
   }

   .organiser {
      grid-area: 2 / 3 / 3 / 4;
   }

   .photo {
      grid-area: 1 / 5 / 3 / 6;

      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
   }

   .types {
      grid-area: 1 / 4 / 3 / 5;

      svg {
         width: 100%;
      }
   }
}
