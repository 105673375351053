@import '../globals.module';

@mixin button {
   border: none;
   background: none;
   padding: 0;

   &:focus {
      outline: none;
   }
}

.nearbyFilterEditor {
   @include button;
   text-align: left;

   ul {
      margin: 0;
      padding-left: 1rem;
   }
}

.summary {
   padding: 1rem;
}

.delete {
   @include button;
   padding: 1rem;
   font-weight: 600;
   color: $red;
   border-top: 1px solid #efefef;
   border-bottom: 1px solid #efefef;
}

.suggestionWarning {
   span {
      color: orange;
   }
}
