@import './globals.module.scss';

.input {
   background: white;
   border: none;
   border-radius: 2px;
   padding: 0 12px;
   height: 2.5rem;
   width: 10rem;
}

.nativePicker {
   .input {
      display: flex;
      align-items: center;
      cursor: pointer;
   }
}

.clear {
   border: none;
   background: none;
   width: 100%;
   padding: 0 0 0.5rem;
   font-weight: 600;
   color: $accent;
}
