.recurrenceEditor {
   display: flex;
   align-items: center;
}

.until {
   margin: 0 0.5rem;
}

.button {
   border: none;
   background: white;
   border-radius: 2px;
   height: 35px;
   padding: 0px 12px 2px;

   &::after {
      margin-left: 1rem;
      margin-bottom: -1px;
   }
}
