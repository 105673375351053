@import './globals.module.scss';

.svg {
   color: $accent;
   height: 15rem;
}

.body {
   display: flex;
   flex-flow: column;
   align-items: center;

   button {
      margin-top: 1rem;
      min-width: 7rem;
   }
}

.mobile {
   position: fixed;
   left: 0;
   top: 0;
   bottom: 0;
   right: 0;
   z-index: 9999999;

   display: flex;
   flex-flow: column;
   align-items: center;
   background: white;
   font-size: 5vmin;
   text-align: center;

   svg {
      width: 50vmin;
      margin-top: 20vmin;
   }
}
