.spinner {
   fill: currentColor;
}

.hand {
   animation: rotate 1.2s linear infinite;
   transform-origin: center;
}

.invisible {
   fill: none;
}

@keyframes rotate {
   100% {
      transform: rotate(360deg);
   }
}
