.itemContainer {
   overflow-y: auto;
   position: relative;
   max-height: 400px;
}

.menu {
   position: absolute;
   background: white;
   z-index: 99;
   box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);

   > * {
      cursor: pointer;
   }
}

.curtain {
   position: absolute;
   left: 0;
   top: 0;
   right: 0;
   bottom: 0;
   background: #ffffff88;
}
