.button {
   border: none;
   background: none;
   padding: 0;
   color: inherit;
   text-align: left;

   &:focus {
      outline: none;
   }
}

.placeholder {
   color: #767676;
}

.teams {
   margin: 0;
   padding: 0;
   list-style: none;

   li {
      display: flex;
      padding: 0.5rem;
      border-bottom: 1px solid #efefef;
   }
}

.team {
   flex: 1;
   display: flex;
   align-items: center;
   margin: 0;

   > input {
      margin-right: 1rem;
   }
}

.name {
   flex: 1;
}

.members {
   display: flex;
   padding-right: 0.5rem;
   align-items: center;
}
