@import './globals.module';

.bookingCursor {
   position: absolute;
   background: $booking;
   border: 2px solid black;
   contain: size layout style;
   z-index: 99;
   touch-action: none;

   transition-property: left, min-width;
   transition-duration: 50ms;
   transition-timing-function: ease-out;

   &.dragging {
      transition-property: none;
   }
}

$size: 22px;
$padding: 10px;

.leftSelection,
.rightSelection {
   color: black;
   position: absolute;
   cursor: ew-resize;

   touch-action: none;

   padding: $padding;
   width: $size + $padding * 2;
   top: -$size - $padding;
}

.leftSelection {
   left: -$size - $padding;
}

.rightSelection {
   right: -$size - $padding;
   transform: scaleX(-1);
}
