.descriptionEditor {
   border: none;
   background: none;
   padding: 0;
   text-align: left;

   white-space: pre-wrap;

   &:focus {
      outline: none;
   }

   span {
      color: #767676;
   }
}

.background {
   background: #f1f2f3;
   box-shadow: 0 5px 4px -4px inset #00000088;
}

.wrapper {
   padding: 0.5rem;
   display: flex;
   flex-flow: column;
}

.border {
   background: white;
   border: 1px solid #ccc;
   display: flex;
   flex-flow: column;
   padding: 0.25rem;
}

.editor {
   border: none;
   min-height: 10rem;
   resize: none;
}
