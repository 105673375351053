@import './globals.module';

.datePicker {
   display: flex;
   align-items: stretch;

   .button {
      color: inherit;
      border: none;
      background: none;
      cursor: pointer;
      padding: 0 0.5rem;

      display: flex;
      justify-content: center;
      align-items: center;

      svg {
         height: 28px;
      }

      + .button {
         margin-left: 0.5rem;
      }

      &:focus {
         outline: none;
      }
   }
}
