@import './globals.module';
@import './schedule-vars.module';

.schedule {
   display: flex;
   color: $foreground;
   position: relative;
}

$roomSeparatorHeight: 15px;
$panelSpacing: 0.25rem;
$panelInnerHeight: 2.5rem;
$panelHeight: $panelInnerHeight + $panelSpacing * 2;

:export {
   segmentWidth: $segmentWidth;
   segmentSpacing: $segmentSpacing;
   segmentHours: $segmentHours;

   roomSeparatorHeight: $panelHeight;
   roomPanelHeight: $panelHeight;
}

.infos {
   display: flex;
   flex-flow: column;
   flex: 3;
   min-width: 0;
   max-width: 20rem;
}

.info {
   padding: 0.25rem 0;
   padding-left: 1rem;
   padding-right: 2rem;

   .name {
      margin-left: 0.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
   }

   flex: 0 0 auto;
   display: flex;
   align-items: center;

   font-weight: 600;

   .addRemoveButton {
      border-radius: 50%;
      border: none;
      background: transparent;
      padding: 0;
      margin-right: 1rem;

      img {
         height: 2rem;
      }
   }

   .addRemoveButtonPlaceholder {
      width: 2rem;
      margin-right: 1rem;
   }
}

.lines {
   flex: 1 0 0;
   display: grid;
   align-items: center;
   grid-auto-rows: 1fr;
}

@mixin floating-panel($height: $panelHeight) {
   position: absolute;
   height: $height;
   left: 0;
   right: 0;

   background: $background;
   padding: $panelSpacing 0;

   font-size: 1.5rem;
   color: $accent;
   font-weight: 300;

   display: flex;
   flex-flow: column;

   > div {
      flex: 1;
      display: flex;
      align-items: center;
      padding-left: 1rem;
      background: $panelBackground;
      pointer-events: initial;

      :global .dropdown-toggle {
         background: none;
         cursor: pointer;
      }
   }
}

.panelWrapper {
   height: $panelHeight;
   pointer-events: none;

   .optionalAttendeesPanel {
      @include floating-panel();
      z-index: 2;
   }

   .suggestedResourcesPanel {
      @include floating-panel();
      z-index: 2;
   }

   .additionalResourcesPanel {
      @include floating-panel();
      z-index: 2;

      .locationPicker {
         margin-left: -4px;

         :global .dropdown-toggle {
            &::after {
               top: 17px;
            }
         }
      }
   }
}

.scrollWrapper {
   flex: 7;
   margin-right: 1.5rem;
   display: flex;
   flex-flow: column;
   overflow: hidden;

   position: relative;

   &.shadowLeft::before {
      content: '';
      position: absolute;
      width: 14px;
      height: 100%;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)) no-repeat;
      z-index: 1;
   }

   &.shadowRight::after {
      content: '';
      position: absolute;
      width: 14px;
      height: 100%;
      right: 0;
      background: linear-gradient(to left, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)) no-repeat;
      z-index: 1;
   }
}

.scroller {
   flex: 1 0 0;
   display: flex;
   flex-flow: column;
   overflow-x: auto;
   position: relative;

   // grid
   background: linear-gradient(to right, transparentize($foreground, 0.95), transparentize($foreground, 0.95) 2px, transparent 2px, transparent) repeat-x;
   background-attachment: local;
   background-position: -1px 5px;
   background-size: (($segmentWidth + $segmentSpacing * 2) * 2) 100%;
}

$gapForBookingCursor: 0;
$timesHeight: 3rem;

.times {
   min-height: $timesHeight;
   max-height: $timesHeight;
   white-space: nowrap;
   justify-content: space-between;

   span {
      flex: 0 0 auto;
      display: inline-block;
      min-width: ($segmentWidth + $segmentSpacing * 2) * 2;
      max-width: ($segmentWidth + $segmentSpacing * 2) * 2;
      padding-left: $segmentSpacing;

      &:last-child {
         min-width: ($segmentWidth + $segmentSpacing) * 2;
         max-width: ($segmentWidth + $segmentSpacing) * 2;
      }
   }
}

.currentTime {
   position: absolute;
   min-width: 1px;
   min-height: 100%;
   background-color: $foreground;
   opacity: 0.5;
   z-index: 5;
}

.booking {
   margin-top: -$gapForBookingCursor;
   min-height: calc(100% - #{$timesHeight - $gapForBookingCursor});
   top: $timesHeight;
}

.clickable {
   cursor: pointer;
}

.highlight {
   background: white;
}

.resourcePicker {
   margin-left: 1rem;
}

.locationPicker {
   margin: 0 1rem;

   .locationPickerToggle {
      border-radius: 3px;
      background: white;
      height: 32px;
      padding-left: 12px;
      padding-right: 32px;
      display: flex;
      align-items: center;

      span {
         flex: 1;
         overflow: hidden;
         text-overflow: ellipsis;
         max-width: none;
      }

      &::after {
         position: absolute;
         right: 1rem;
         top: 14px;
      }
   }

   .location {
      position: relative;

      &.selected::before {
         content: '';
         position: absolute;
         left: 0.25rem;
         height: 16px;
         width: 16px;
         top: calc(50% - 8px);
         background: center / 10px no-repeat url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA70lEQVR42l2QOw6CQBCGB1QEQQOJiXR6CG7AUSAh0gAhFCS8wtPLWGthY2dhYqE2XkZ3CLshFpt/MvvNzD8DRVHA+OV53muWZRzRCdEJ5tgnBQYIqqpiijkKcWVZ9pqmKdc0Dfi+v9B1/Wia5r7rOmAVURRNMa7rGiFZ07QTAHwlSXqgDQSnhmEcFEW5O46zCcNwpqrqeYCelmXt2rblIY7jOam+4IcsyzcC9Z1EUXzZtr3FsUmS8EBozvO8FYUH6E0h4pnvl8FgML8k3a6CIHz+IXYeNItwEASK67prMgVz/PiuLMCz4NZYMO5Eb/sDDjyIrBo6bq0AAAAASUVORK5CYII=');
      }
   }
}

.corner > span {
   font-size: 1.5rem;
   font-weight: 300;
   color: $accent;
   margin-left: 0.75rem;
}

.loading {
   @include floating-panel();
   background: url('./loading.svg') white;
}

.expanderButton {
   display: inline-flex;
   align-items: center;
   margin-right: 0.5rem;
   cursor: pointer;

   svg {
      display: block;
      height: 24px;
      transition: transform 150ms ease-out;
   }

   &.expanded svg {
      transform: rotate(90deg);
   }
}
