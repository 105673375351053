@import "./globals.module";

.delegationPicker {
   width: 20rem;
   margin-right: 1rem;
   display: flex;
   flex-flow: column;
   position: relative;
   font-size: 1rem;
   color: black;

   .cheveron {
      height: 1rem;
      transform: rotate(-90deg);
      margin-left: auto;
      margin-right: 1rem;
   }

   &.isOpen {
      background: #f0f0f0;
   }

   > button {
      outline: none;
      padding: 0;
      border-radius: 0.5rem;
      border: none;
      text-align: left;
      display: flex;
      align-items: center;
      font-size: inherit;
      font-family: inherit;
   }

   .autoComplete {
      display: flex;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 99;

      .inputWrapper {
         position: absolute;
         left: 0;
         right: 0;
         z-index: 1;
         background: white;
         //border-bottom: 1px solid #888;
         display: flex;
      }

      input {
         flex-grow: 1;
         margin: 0.5rem 1rem;
         border: none;
         border-bottom: 1px solid black;
         min-width: 0;
         font-size: inherit;
         font-family: inherit;
      }
   }

   .menu {
      z-index: 0;
      margin-left: -2rem;
      min-width: 0;
      right: 0;
   }
}

.item {
   display: flex;
   align-items: center;
   padding: 0.5rem 1rem;
   min-width: 0;

   &.highlighted {
      background: #eee;
   }

   .details {
      display: flex;
      flex-flow: column;
      margin-left: 1rem;
      min-width: 0;
   }

   .name {
      font-weight: 700;
      font-size: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
   }

   .desc {
      font-size: 0.8rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
   }
}
