@import './globals.module';

.otpStatus {
   max-width: 650px;
}

.spinner {
   margin-right: 0.5rem;
   height: 24px;
}

.message {
   padding: 0.5rem 0.25rem;
   margin: 0;

   &.error {
      color: $red;
   }
}
