@import './globals.module';
@import './schedule-vars.module';

.line {
   display: flex;
   min-height: $lineHeight;
   position: relative;

   &.loading {
      background-image: url('./loading.svg');
      width: ($segmentWidth + 2 * $segmentSpacing) * 24 / $segmentHours;
   }

   &.highlight:not(.loading)::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: -0.3rem;
      bottom: -0.3rem;

      background: white;
   }
}

.segment {
   $radius: 4px;
   min-width: $segmentWidth / 2;
   height: $lineHeight;
   background: #ddd;
   position: relative;

   &.busy {
      background: $accent;
   }

   &.free {
      background: transparentize($green, 0.66);
   }

   &.even {
      border-top-left-radius: $radius;
      border-bottom-left-radius: $radius;
      margin-left: $segmentSpacing;
   }

   &.odd {
      border-top-right-radius: $radius;
      border-bottom-right-radius: $radius;
      margin-right: $segmentSpacing;
   }
}

.workingHours {
   position: absolute;
   top: -0.3rem;
   bottom: -0.3rem;
   background: rgba(0, 0, 0, 10%);
}
