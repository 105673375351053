@import './globals.module';

.meetingLink {
   padding: 1rem;
   display: flex;
   flex: 1;
}

.error {
   color: $red;
}

.spinner {
   position: fixed;
   left: 0;
   top: 0;
   bottom: 0;
   right: 0;
   margin: auto;
   color: $accent;
   width: 30vmin;
}

.notFound {
   padding: 1rem;
   display: flex;
   flex-flow: row;
   align-items: center;

   p {
      margin:0;
      margin-left: 1rem;
      padding:0;
   }

   .icon {
      font-size: 4rem;
      color: $accent;
   }
}
