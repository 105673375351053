$background: #f5f5f5;
$foreground: black;

$panelBackground: #e8e8e8;
$accentedForeground: $panelBackground;

$accent: #464676;   //8.75
$accent-light: #6264a7;
$shadedBg: #ddd;// lighten($accent, 38.3);

/* Colour changes to bring is in line with WCAG 2.1 (contrast levels >= 5) */
$red: #CF1313;  // 5.59
$green: #137d52; // 5.14
$booking: #464676; 

/*
$red: #ef4343;  (3.78)
$green: #138d52; (4.23)
$booking: transparentize($accent, .75); failed
*/



