.combobox {
   position: relative;
}

.caret {
   position: absolute;
   right: 0;
   top: 0;
   bottom: 0;
   margin: auto;
   display: flex;
   padding: 0 0.5rem;
   font-size: 10px;
   cursor: pointer;

   &::before {
      margin: auto;
   }
}

.item {
   cursor: pointer;
   padding: 0.25rem 0.5rem;
   padding-right: 2rem;

   &:first-child {
      margin-top: 0.5rem;
   }

   &:last-child {
      margin-bottom: 0.5rem;
   }

   &:hover {
      background: #f8f8f8;
   }

   &.selected {
      background: #eee;
   }
}
