.Splash {
   position: absolute;
   left: 38rem;
   right: 0;
   top: 8rem;
   bottom: 5rem;
   pointer-events: none;

   font-size: 7vw;
   color: white;
   text-shadow: #000000cc 0 0 10px;
   animation: fade 2s cubic-bezier(0.76, 0.05, 0.86, 0.06) forwards;

   display: flex;
   align-items: center;
   justify-content: center;
   text-align: center;
   white-space: pre-line;
   overflow: hidden;

   @keyframes fade {
      0% { opacity: 1; }
      100% { opacity: 0; }
   }
}
