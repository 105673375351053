.item {
   display: flex;
   align-items: center;
   padding: .5rem 1rem;

   &.highlighted {
      background: #eee;
   }
}

.details {
   display: flex;
   flex-flow: column;
   margin-left: 1rem;
}

.name {
   font-weight: 600;
}
