@import './globals.module';

.header {
   display: grid;
   padding: 0.5rem 1rem;
   grid-template: auto / auto 1fr auto;

   color: white;
   background: $green;

   &.busy {
      background: $red;
   }
}

.time {
   justify-self: flex-start;
}

.title {
   align-self: center;
   font-size: 2.5rem;
   font-weight: 300;
   text-align: center;
}

@media (orientation: portrait) {
   .header {
      grid-template: auto auto / 1fr auto;
   }

   .title {
      grid-area: 2 / 1 / 3 / 3;
      margin-top: 1rem;
      padding-top: 0.25rem;
      border-top: 1px solid #ffffff88;
      font-weight: normal;
   }
}

.buttons {
   display: flex;

   > * {
      color: inherit;
      border: none;
      background: none;
      cursor: pointer;
      text-decoration: none;
      text-align: center;

      padding: 0;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-content: center;

      &:hover {
         text-decoration: none;
         color: inherit;
      }

      &:disabled {
         opacity: 0.5;
      }

      svg {
         height: 3rem;
         margin-bottom: 0.5rem;
      }
   }

   > * + * {
      margin-left: 1rem;
   }
}
