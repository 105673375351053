@import './globals.module';

@mixin button {
   background: none;
   color: $accent;
   border: #ccf 1px solid;
   display: flex;
   justify-content: center;
}

.body {
   display: flex;
   flex-flow: column;
   background: white;
   font-size: 1.2rem;

   hr {
      align-self: stretch;
      margin: 1rem 0;
   }
}

.titleRow {
   display: flex;
   flex-flow: row;
   margin-bottom: 1rem;
}

.row {
   display: flex;
   flex-flow: row;
}

.wrap {
   flex: 1;
   min-width: 0;
   overflow-wrap: break-word; // to break the long _______ thing
}

.locationInfo {
   margin-top: 0.5rem;
}

.seperator {
   border-bottom: #eee 1px solid;
   margin-bottom: 1rem;
}

.headerColumn {
   display: flex;
   flex-flow: column;
   justify-content: center;
   align-items: center;
   min-width: 2.5rem;
   margin-right: 1rem;
}

.meetingTitle {
   font-size: 2rem;
   color: $accent;
   line-height: 2.5rem;
}

.attendeeSummary {
   margin-right: 1rem;
}

.attendeeInfo {
   font-size: 1rem;
   color: #999;
}

.row .addResource {
   color: $accent;
   flex-flow: column;
   margin-left: auto;
   font-size: 0.8rem;

   svg {
      height: 1.5rem;
   }
}

.titleIcon {
   font-size: 0.8rem;
   color: $accent;
   margin-bottom: auto;
   height: 2.5rem;
   display: flex;
   align-items: center;
}

.descIcon {
   color: $accent;
   font-size: 1.5rem;
   margin-bottom: auto;
   height: 2.5rem;
   display: flex;
   align-items: center;
}

.description {
   margin-top: 0.25rem;
}

.icon {
   font-size: 1.5rem;
   color: $accent;
}

.actionBar {
   background: $background;
   border-bottom-left-radius: 0.3rem;
   border-bottom-right-radius: 0.3rem;
   margin: 1rem -1rem -1rem;
   padding: 1rem;

   .actionBarButtons {
      display: flex;
      flex-flow: row;
      font-size: 1rem;

      > button {
         width: 8rem;
         margin-right: 1rem;
         font-weight: normal;
      }

      .icon {
         font-size: 1.2rem;
         margin-right: 0.5rem;
      }
   }
}

.acceptButton {
   background: $accent;
}

.statusButtonIcon {
   color: white;
   margin-right: 1rem;
}

.dropdownIcon {
   color: $accent;
   margin-right: 0.5rem;
}

.changeName {
   display: flex;
   flex-flow: column;

   .emailOrganiser {
      display: flex;
      flex-flow: row;
      color: $accent;
      margin-bottom: 1rem;

      .emailOrganiserLabel {
         flex: 1;
         display: flex;
         align-items: center;
         user-select: none;

         > * {
            margin-left: auto;
         }
      }
   }

   .title {
      display: flex;
      flex-flow: row;
      color: $accent;
   }

   .icon {
      color: $accent;
      margin-right: 1rem;
      margin-bottom: 1rem;
   }

   .actionButtons {
      display: flex;
      flex-flow: row;

      > button {
         font-size: 1rem;
         width: 6rem;
         margin-right: 1rem;
      }
   }

   .edit {
      resize: none;
      border: $accent 1px solid;
      width: 100%;
      margin-bottom: 1rem;
      padding: 0.5rem;
   }
}
