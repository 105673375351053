@import './globals.module';

.login {
   max-width: none;
   display: inline-block;

   .body {
      min-width: 38rem;
   }

   form {
      margin-top: 1rem;
   }

   label {
      color: $accent;
      font-weight: 600;
   }

   .modeButtons {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 0.5rem;

      > * {
         font-size: 2rem;
         padding: 0.5rem 1.5rem;
         border-radius: 3px;
         margin-right: 1rem;
         color: $accent;
         border: 2px solid $accent;
         background: none;

         &.active {
            background: $accent;
            color: $accentedForeground;
         }
      }
   }
}

.error {
   margin-top: 1rem;
   color: red;
}

.logo {
   height: 3rem;
}

.logoAccent {
   fill: white;
}

.loading {
   margin-top: 1rem;
   background: url('./loading.svg') white;
   height: 1.5rem;
}

.buttons {
   display: flex;
   justify-content: flex-end;
   > * {
      margin-left: 0.5rem;
   }
}

.spinner {
   margin-right: 0.5rem;
   height: 24px;
}

.email {
   font-weight: 600;
}

.message {
   padding: 0.5rem 0.25rem;
   margin: 0;

   &.error {
      color: $red;
   }
}

.waiting {
   padding: 0.5rem 1rem;
   display: flex;
   margin: 0;

   svg {
      height: 1.5rem;
      margin-right: 0.5rem;
   }
}

.meetingLinkInfo {
   max-width: 650px;
}
