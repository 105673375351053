@import 'globals.module';


.loginAutoCompleteInput {
   display: flex;
   align-items: center;
   padding-left: 1rem;
   padding-right: 1.5rem;


   .comboBox {
      flex: 1;
      display: flex;
   }
   
   .input {
      border: none;
      background: none;
      border-bottom: solid 1px #666;
      padding: 0.25rem 0 0;
      margin-bottom: 0.25rem;
      flex: 1;

      &:focus {
         margin-bottom: calc(0.25rem - 1px);
         border-bottom: solid 2px $accent;
      }
   }
}

.icon {
   margin-right: 1rem;
   color: #666;
}

.item {
   display: flex;
   align-items: center;
   background: #f4f4f4;
   margin-right: 0.5rem;
   padding: 1px;
   cursor: default;
}

.details {
   display: flex;
   flex-flow: column;
   margin-left: 1rem;
   padding: .5rem 1rem;
}

.name {
   font-weight: 600;
}

