@import '../globals.module';

.toggle {
   display: inline-flex;
   position: relative;
   cursor: pointer;

   $width: 34px;
   $height: 14px;
   $thumb: 20px;

   box-sizing: content-box;
   width: $width;
   height: $height;
   padding: max(0, ($thumb - $height) / 2);

   .track {
      flex: 1;
      border-radius: 10px;

      background: #bdbdbd;

      display: flex;
      align-items: center;
      position: relative;
      transition: background 100ms ease-out;
   }

   input {
      position: absolute;
      opacity: 0;
   }

   .thumb {
      position: absolute;
      width: $thumb;
      height: $thumb;
      left: $height / 2 - $thumb / 2;
      top: 0;
      bottom: 0;
      margin: auto 0;
      border-radius: 50%;
      transition: transform 100ms ease-out;

      background: #ececec;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 40%);
   }

   &.checked {
      .track {
         background: transparentize($accent-light, 0.75);
      }

      .thumb {
         transform: translate($width - $height, 0);
         background: $accent-light;
      }
   }

   &.disabled {
      .track {
         background: #cdcdce;
      }

      .thumb {
         background: #7b8597;
      }
   }
}

.toggleWithLabel {
   display: flex;
   align-items: center;
   justify-content: space-between;
}
