@import '../globals.module';

.spinner {
   margin: auto;
   color: $accent;
   width: 30vmin;
}

.form {
   display: flex;
   flex-flow: column;
}

.input {
   border: none;
}

.button {
   margin: 2rem auto 0;
   padding: 1rem 2rem;
   font-weight: 600;
   color: white;

   svg {
      height: 1.5rem;
      margin-right: 0.5rem;
   }
}

.message {
   padding: 0.5rem 1rem;
   padding-left: 3rem;
   margin: 0;

   &.error {
      color: $red;
   }
}

.waiting {
   padding: 0.5rem 1rem;
   display: flex;
   margin: 0;

   svg {
      height: 1.5rem;
      margin-right: 0.5rem;
   }
}
