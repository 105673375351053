.formField {
   display: flex;
   align-items: center;
   margin: 0;

   &:active {
      background: none;
   }

   + .formField:not(.combine) {
      > .body {
         border-top: 1px solid #efefef;
      }
   }
}

$hor-margin: 1rem;

.icon {
   padding: $hor-margin;
   align-self: flex-start;
   min-width: 24px;
   min-height: 24px;
   box-sizing: content-box;
   font-size: 18px;
   display: flex;
   justify-content: center;
   align-items: center;

   svg {
      height: 24px;
   }
}

.body {
   flex: 1;
   margin: 0 $hor-margin;
   display: flex;
   min-height: 100%;
   min-width: 0;

   > * {
      margin: 1rem 0;
      flex: 1;
   }
}
