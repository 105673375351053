@import './globals.module.scss';

.Toast {
   background: $red;
   color: white;
   box-shadow: 0 0 3px black;
   padding: 0.25rem 0.5rem;
   margin: 0.5rem;
   border-radius: 3px;
   position: relative;
   pointer-events: all;

   width: 30rem;
   max-width: calc(100vw - 2rem);
   min-height: 5rem;
   overflow: hidden;
   text-overflow: ellipsis;
   display: flex;
   flex-flow: column;

   .title {
      font-weight: 600;
      margin-right: 20px;
   }

   .detail {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 3.5rem;
      font-size: 0.8rem;
      margin-top: 0.5rem;
   }

   button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0 0.5rem;
      background: none;
      border: none;
      color: inherit;

      svg {
         fill: currentColor;
         height: 14px;
      }
   }
}

.ToastHost {
   position: absolute;
   top: 100px;
   left: 50%;
   transform: translateX(-50%);
   bottom: 0;
   overflow: hidden;
   pointer-events: none;

   display: flex;
   flex-flow: column;
   justify-content: flex-end;
   padding: 0.5rem;
}
