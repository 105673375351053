.footer {
   padding: 1rem;
   color: white;

   position: fixed;
   bottom: 0;
   right: 1rem;
   z-index: -1;

   display: flex;
}

.logo {
   height: 3rem;
}
