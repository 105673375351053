@import './globals.module';

.participantEditor {
   display: flex;
   flex-flow: row wrap;
   cursor: text;
   padding-left: 0.5rem;
   padding-bottom: 0.5rem;

   > * {
      margin-top: 0.5rem;
   }
}

.item {
   display: flex;
   align-items: center;
   border-radius: 34px;
   background: #f4f4f4;
   margin-right: 0.5rem;
   padding: 1px;
   cursor: default;

  
}

.photo {
   margin-right: 0.5rem;
   box-shadow: 0 0 3px #00000022;
   
   border: 2px solid transparent;

   &.free {
      border-color: $green;
   }
   
   &.busy {
      border-color: $red;
   }
   
}

.name {
   flex: 1;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;

   &:last-child {
      margin-right: 1rem;
   }
}

.remove {
   border: none;
   background: none;
   width: 32px;
   align-self: stretch;
   cursor: pointer;
}

.input {
   display: flex;
   align-items: center;
   height: 34px;
   min-width: 15rem;

   input {
      flex: 1;
      border: none;
   }
}

