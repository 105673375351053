@import '../globals.module';

@mixin button {
   border: none;
   background: none;
   padding: 0;
   color: inherit;

   &:focus {
      outline: none;
   }
}

.button {
   @include button;
   text-align: left;
   display: flex;
   flex-flow: column;

   img {
      margin-left: 0.5rem;
      height: 1.2rem;
      vertical-align: sub;
   }

   ul {
      margin: 0;
      padding-left: 1rem;
   }
}

.quantity {
   display: flex;
   justify-content: space-between;

   .input {
      text-align: right;
   }
}

.extra {
   flex: 1;
   display: flex;
   flex-flow: column;
}

.divider {
   flex: 0 1;
   margin: 0.5rem 1rem;
   min-width: 1px;
   background: #efefef;
}

.input {
   border: none;
   padding: 0;
   pointer-events: none;
   width: 4rem;
}

.attributes {
   display: flex;
   flex-flow: row wrap;
   margin-bottom: 0;

   > label {
      width: 100%;
   }

   .fake {
      visibility: hidden;
      width: 0;
      margin: 0;
      padding: 0;
   }

   button {
      @include button;
      padding: 1rem;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;

      img {
         height: 24px;
      }

      &.active {
         background: $accent;

         img {
            filter: invert(1);
         }
      }
   }
}

.delete {
   @include button;
   padding: 1rem;
   font-weight: 600;
   color: $red;
   border-top: 1px solid #efefef;
   border-bottom: 1px solid #efefef;
}

.loading {
   color: #888;
}

.invalid {
   color: $red;
   text-decoration: line-through;
}

.unavailable {
   color: $red;
}

.resources {
   margin-top: 1rem;
}
