.nativePicker {
   margin: 0;
   position: relative;
   font-weight: normal;

   > input {
      position: absolute;
      opacity: 0;
      width: 0;
   }
}
