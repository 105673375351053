@import './globals.module';

.timielinePopup {
   display: flex;
   background: white;
   padding: 1rem 3rem;
   box-shadow: 0px 0px 5px 0px #00000077;
}

.header {
   display: flex;
   margin-bottom: 0.5rem;

   header {
      font-weight: 600;
      font-size: 1.5rem;
   }
}

.attributes {
   display: flex;
   margin-left: 2rem;

   img {
      width: 1.5rem;
      object-fit: contain;

      & + img {
         margin-left: 1rem;
      }
   }
}

.details {
   display: flex;
   flex-flow: column;
   flex: 1;
   min-width: 0;
}

.slots {
   display: flex;
   overflow-x: auto;
   flex: 1;
   align-items: flex-end;

   .slot + .slot {
      margin-left: 0.25rem;
   }
}

.slot {
   flex: 0 0 auto;
   display: flex;
   flex-flow: column;
   cursor: pointer;
   outline: none;

   &.busy {
      cursor: default;
   }
}

.slotBox {
   display: grid;
   grid-template: 1fr auto auto / auto 1fr;

   border: 2px solid #c4c4c4;
   padding: 0.5rem;
   width: 18rem;
   height: 7rem;

   svg {
      align-self: flex-end;
      width: 30px;
      height: 30px;
      fill: #7b7b7b;
      grid-area: 2 / 1 / 4 / 2;
      margin-right: 0.5rem;
   }
}

.title {
   font-weight: 600;
   font-size: 1.2rem;
   color: $green;

   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
   line-height: normal;

   grid-area: 1 / 1 / 2 / 3;

   .busy & {
      color: $red;
   }
}

.time {
   display: flex;
}

.organiser {
   font-weight: 600;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
}

.plan {
   align-self: center;
   margin-right: 3rem;
   display: flex;
   border: 5px solid #d0d0d0;
   border-radius: 5px;
   height: 11rem;
   width: 23rem;
   cursor: pointer;
   outline: none;

   img {
      width: 100%;
      align-self: stretch;
      object-fit: contain;
   }

   div {
      font-size: 2rem;
      align-self: center;
      margin: auto;
      color: #353535;
   }
}

.modal {
   max-width: 800px;

   :global {
      .modal-body img {
         width: 100%;
      }
   }
}
