@import './globals.module';

@mixin background($child: '') {
   $pending: linear-gradient(-45deg, #fff2 0%, #fff2 25%, transparent 25%, transparent 50%, #fff2 50%, #fff2 75%, transparent 75%, transparent 100%) 0 / 80px 80px;

   // @at-root .timelineItem #{$child} {
   //    background: transparentize(#6569ac, 0.2);
   // }

    @at-root .timelineItem #{$child} {
      background: #6569ac;
   }

   @at-root .timelineItem.current #{$child} {
      background: transparentize(#ffa44d, 0.2);
   }

   @at-root .timelineItem.started #{$child} {
      background: $red;
   }

   @at-root .timelineItem.pending #{$child} {
      background: $pending, transparentize(#6569ac, 0.2);
   }

   @at-root .timelineItem.current.pending #{$child} {
      background: $pending, transparentize(#ffa44d, 0.2);
   }

   @at-root .timelineItem.started.pending #{$child} {
      background: $pending, $red;
   }
}

.timelineItem {
   flex: 0 0 auto;
   display: flex;
   flex-flow: row;

   height: 10rem;
   padding: 1rem;
   margin-left: 10rem;
   margin-bottom: 1.5rem;
   scroll-margin: 1.5rem;

   color: white;
   @include background();

   .info {
      position: relative;
      display: flex;
      flex-flow: column;
      width: 25rem;
      cursor: pointer;
      line-height: 1;
   }

   .time {
      font-weight: 600;
      font-size: 1.2rem;
   }

   .subject {
      margin: auto 0;
      font-size: 1.75rem;
      line-height: normal;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
   }

   .types {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;

      > svg {
         height: 2rem;
         margin-left: 0.5rem;
      }
   }

   .organizer {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 300;
   }

   .buttons {
      position: relative;
      display: flex;
      flex-flow: row;
      align-items: center;

      margin-left: 1rem;
      border-left: 3px solid #fff4;

      > * {
         color: inherit;
         border: none;
         background: none;
         cursor: pointer;
         text-align: center;
         padding: 0.5rem 1rem;

         width: 5rem;
         display: flex;
         flex-flow: column;

         &:disabled {
            opacity: 0.5;
         }

         > svg {
            height: 3rem;
            margin-bottom: 0.5rem;
         }

         &.toggled {
            background: #ffffff44;
         }
      }
   }

   .periods {
      left: 0;
      top: 100%;
      position: absolute;
      padding: 1rem;
      margin-top: 1.5rem;
      margin-left: 0;

      @include background('.periods');

      $spacing: 1rem;
      display: flex;
      flex-flow: row;
      width: auto;

      > * {
         margin: 0 $spacing / 2;

         svg {
            width: 70px;
            height: 70px;
         }
      }
   }
}
