.page {
   flex: 1;
   min-height: 0;
   display: flex;
   flex-flow: column;
}

.body {
   flex: 1;
   min-height: 0;
   overflow: auto;

   display: flex;
   flex-flow: column;
}
