@import './globals.module';

.login {
   border: 1px solid $accent;
   border-radius: 2px;
   background: white;
   color: $accent;
   padding: 0.25rem 0.5rem;
}

.organisationButton {
   display: flex;
   flex-flow: column;
   align-items: center;
   color: $accent;

   img {
      width: 108px;
      height: 72px;
   }

   .name {
      font-weight: 600;
   }  
}

.timeHeader {
   min-width: 4rem;
}

.timeButton {
   display: flex;
   flex-flow: column;
   align-items: center;
   color: $accent;
   min-width: 5rem;

   > div:first-child {
      font-size: 0.8rem;
   }

   > div:last-child {
      font-weight: 600;
   }
}

.moreOptions {
   margin-top: 1rem;
}

