@import './globals.module';

.body {
   display: flex;
   flex-flow: column;
   background: white;

   h1 {
      font-weight: 300;
      margin-left: 1rem;
   }

   h2 {
      font-size: 1.5rem;
      margin: 1rem 0;
   }
}

.loginButton {
   min-width: 7rem;
}

.deleteButton {
   background: none;
   color: $red;
   border: 2px solid currentColor;
   font-weight: 600;
}

.message {
   margin: 0 1rem;
}

.buttons {
   display: flex;
   margin-top: 1rem;
   margin-left: 1rem;
   margin-right: 1.5rem;
   align-items: center;
   justify-content: flex-end;

   > * + * {
      margin-left: 1rem;
   }
}

.email {
   margin-left: 1rem;
   height: calc(3rem + 1px);
}

.error {
   color: $red;
   white-space: pre-line;
   margin-top: 1rem;
   margin-left: 1rem;
}

.periods {
   display: flex;

   svg {
      height: 4rem;

      .durationBackground {
         stroke: #c1bfbf;
      }
   }

   > * + * {
      margin-left: 1rem;
   }
}

.em {
   font-weight: 600;
}
