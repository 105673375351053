.popover {
   box-shadow: rgba(0, 0, 0, 0.11) 0 0 3.6px, rgba(0, 0, 0, 0.13) 0 3.4px 14.4px;
   clip-path: inset(0 -15px -15px -15px);
   max-height: 20rem;
   overflow-y: auto;
   overflow-x: hidden;
   white-space: nowrap;
   background: white;

   &.top {
      box-shadow: rgba(0, 0, 0, 0.11) 0 0 3.6px, rgba(0, 0, 0, 0.13) 0 -3.4px 14.4px;
      clip-path: inset(-15px -15px 0 -15px);
   }
}

.container {
   display: flex;

   animation-name: animate;
   animation-duration: 200ms;
   animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
   animation-fill-mode: both;
}

@keyframes animate {
   0% {
      transform: translate(0, 0);
      opacity: 0;
   }
   1% {
      transform: translate(0, -10px);
      opacity: 0;
   }
   100% {
      transform: translate(0, 0);
      opacity: 1;
   }
}
