@import '../globals.module';

.suggestionsContainer {
   display: flex;
   flex-flow: column;
   min-width: 0;

   .suggestions {
      margin-top: 0.5rem;
   }
}

.moreButton {
   border: none;
   background: none;
   outline: none;
   padding: 0 1rem;

   &:focus {
      outline: none;
   }

   text-transform: uppercase;
   text-align: left;
   color: $accent-light;
   font-weight: 600;
}

.resources {
   margin: 1rem 0 0;

   > span {
      color: $accent;
      font-weight: 600;
   }

   .loading {
      margin: 1rem 0;
   }

   .nothingAvailable {
      margin: 1rem 0;
      color: $red;
   }
}

.delete {
   border: none;
   border-top: 1px solid #efefef;
   background: none;
   outline: none;
   padding: 1rem;
   font-weight: 600;

   color: $red;
}

.recurrenceUntil {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
