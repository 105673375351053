@import './globals.module';

.bookingFilter {
   flex: 1;
   display: grid;
   grid-template: auto 1fr / 1fr 1fr;
   background: #f5f5f5;
   --controlBackground: #ccc;

   h2 {
      font-size: 1.1rem;
      font-weight: 600;
      color: $accent;
   }

   > header {
      position: relative;
      grid-area: 1 / 1 / 2 / 3;

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1.5rem;

      background: $accent;
      color: $accentedForeground;
      font-size: 2rem;
      font-weight: 300;
      line-height: 1rem;

      button {
         min-height: 2.5rem;
         font-size: 1rem;
      }

      span {
         position: absolute;
         left: 0;
         top: 0;
         bottom: 0;
         right: 0;
         display: flex;
         align-items: center;
         justify-content: center;
         pointer-events: none;
      }
   }

   > div {
      display: flex;
      flex-flow: column;
      padding: 0.5rem 1rem;
      margin: 0.5rem 0;
      flex: 1 1 0;

      & + div {
         border-left: 1px solid #e0e0e0;
         min-width: 0;
      }
   }

   &:not(.teamsMode) {
      header button {
         background: none;
         color: white;
         font-size: 1.25rem;
      }
   }

   &.teamsMode {
      background: #f2f2f2;
      > header {
         background: #ddd;
         color: #444;
      }
   }
}

.input {
   background: white;
   border: none;
   border-radius: 2px;
   padding: 0 12px;
   height: 2.5rem;
}

.title {
   flex: 1;
}

.agenda {
   margin-left: 0.5rem;

   svg {
      height: 16px;
      margin-right: 0.5rem;
   }
}


.dates {
   flex: 1;
   display: flex;
   align-items: center;

   > * + * {
      margin-left: 0.5rem;
   }

   svg {
      height: 32px;
   }

   .input {
      position: relative;
   }
}

.nativePicker {
   &:not(:first-child) {
      margin-left: 0.5rem;
   }

   .timeInput {
      display: flex;
      align-items: center;
      cursor: pointer;
   }
}

.timeInput {
   width: 7rem;
}

.duration {
   color: #666;
   white-space: nowrap;
}

.toggle {
   margin: 0;
   margin-left: 1rem;
   display: flex;
   align-items: center;
   cursor: pointer;
   line-height: 1;
   white-space: nowrap;

   &:first-child {
      margin-left: 0;
   }

   > * {
      margin-right: 0.5rem;
   }
}

.attendeesField {
   flex-flow: row wrap;
}

.attendees {
   flex: 1;
   display: flex;
   align-items: flex-start;
   background: white;
   border-radius: 2px;
   margin-right: 0.5rem;

   > span {
      color: #888;
      height: 32px;
      margin: 0.5rem;
      display: flex;
      align-items: center;
   }

   .optionalButton {
      margin: 0.5rem;
      border: none;
      background: none;
      color: $accent;
      padding: 0.5rem;
   }
}

.attendeesIcon {
   height: 3.5rem;
   align-self: flex-start;
}

.attendeeRow {
   flex: 1 100%;
   display: flex;
   align-items: center;

   & + & {
      margin-top: 0.5rem;
   }
}

.participantEditor {
   flex: 1;

   &:disabled {
      background: white;
   }
}

.addTeamsMembers {
   svg {
      fill: currentColor;
      width: 1.5rem;
   }

   span {
      height: 0.5rem;
      font-size: 1.5rem;
      font-weight: 900;
   }
}

.teamsToJoin {
   background: white;
   border-radius: 2px;
   padding-top: 0.5rem;
   padding-left: 0.5rem;
   flex-wrap: wrap;

   .addTeam {
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;
   }
}

.teamToJoin {
   display: flex;
   align-items: center;
   padding-left: 12px;
   padding-right: 4px;
   margin-right: 0.5rem;
   margin-bottom: 0.5rem;
   color: black;
   background: #f4f4f4;
   border-radius: 32px;

   > button {
      width: 24px;
      height: 32px;
      color: inherit;
      border: none;
      background: none;
      cursor: pointer;
      padding: 0;
   }
}

.vfxIcon {
   height: 3.5rem;
   align-self: flex-start;
}

section.resourcesSection {
   padding: 0;

   h2 {
      margin-top: 0;
   }
}

section .resources {
   display: flex;
   flex-flow: column;
   width: 100%;
   color: $accent;
   margin-bottom: 0;

   .resource {
      display: grid;
      grid-template: auto auto / auto 1fr auto;
      cursor: pointer;
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      background: white;
      border-radius: 2px;

      > button {
         grid-column: 3;
         grid-row: 1 / span 2;
         color: $accent;
         background: none;
         cursor: pointer;
         border: none;
         text-align: left;
         padding: 0;
         align-self: flex-start;

         svg {
            height: 1.5rem;
         }
      }
   }

   .icon {
      height: 24px;
      grid-row: span 2;
      margin-right: 0.5rem;
   }

   .summary {
      display: flex;
      padding-right: 1rem;

      flex-flow: row wrap;
      align-items: center;
      white-space: nowrap;

      img {
         margin-left: 0.5rem;
         height: 1.2rem;
      }
   }

   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      font-weight: 600;
   }

   .invalid {
      color: $red;
      text-decoration: line-through;
   }

   .unavailable {
      color: $red;
      font-weight: 600;
   }

   .loading {
      color: #888;
   }
}

.suggestions {
   margin: 1rem 0 0.5rem;
}

.buttons {
   display: flex;
   margin-top: 1rem;

   > * + * {
      margin-left: 0.5rem;
   }

   button {
      min-height: 3.5rem;
   }

   .searchMore {
      background: white;

      svg {
         height: 1.5rem;
         margin-right: 0.5rem;
      }
   }
}

.confirmation {
   background: white;
}

.confirmationButtons {
   display: flex;
   margin-top: 1rem;

   > * {
      margin-left: 1rem;
   }
}

.logoutButton {
   color: $accent;
   border: 2px solid currentColor;
   border-radius: 3px;
   min-width: 7rem;
   background: none;
   cursor: pointer;
}

.timeWarning {
   margin-top: 0.5rem;
   white-space: nowrap;

   :global(.fa) {
      margin-top: 0.5rem;
      margin-right: 1rem;
      float: left;
      color: orange;
      font-size: 2rem;
   }
}

section.adhoc {
   padding: 0;

   div {
      background: white;
      width: 100%;
      padding: 1rem;
      color: $accent;
      border-radius: 2px;
      margin-bottom: 1rem;
   }

   span {
      font-weight: 600;
   }
   .anotherRoom {
      color: red;
   }
}

.permissions {
   display: flex;
   align-items: center;
}

.permissionsLabel {
   margin-right: 1rem;
}
