@import './globals.module';

.permissionCombo {
   display: flex;
   align-items: center;
}


.button {
   border: none;
   background: white;
   border-radius: 2px;
   height: 35px;
   padding: 0px 12px 2px;

   &::after {
      margin-left: 1rem;
      margin-bottom: -1px;
   }
}

div.modal {
   max-width: 600px;
}

.permissionEditor {
   background: white;

   .userCombo {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
   }


   ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-flow: column;
   }

   label {
      display: flex;
      flex-flow: row;
   }

   .name {
      margin-left: 1rem;
   }

   
   .buttons {
      display: flex;
      align-items: center;
      margin-top: 2rem;

      .save {
         height: 3rem;
         min-width: 8rem;
      }

      .cancel {
         height: 3rem;
         min-width: 8rem;
         margin-left: 0.5rem;
      }
   }
}
