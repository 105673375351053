.loginTeams {
   margin: 0 auto;
   display: flex;
   flex-flow: column;
   justify-content: center;
   align-items: center;
   flex: 1;

   .error {
      margin-top: .5em;
      line-height: 1.2em;
      height: 1.2em;
      color: red;
   }
}
