@import './globals.module';

.modal {
   max-width: 800px;
}

.resourceDialog {
   background: white;
   display: flex;
   padding: 0;
   min-height: 36rem;

   > div {
      display: flex;
      flex-flow: column;
      margin: 1rem 0;
   }

   hr {
      margin: 1rem 0;
   }

   hr + hr {
      display: none;
   }
}

.filter {
   flex: 2;
   padding: 0 1rem;
}

.multi {
   display: flex;
   flex-flow: row wrap;
   margin-bottom: -0.5rem;

   > * {
      color: $accent;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
   }
}

.locationIcon {
   align-self: flex-start;
   height: 2.5rem;
}

.toggleWithIcon {
   color: $accent;
   font-size: 0.8rem;
   min-width: 4rem;

   svg,
   img {
      height: 2rem;
   }
}

.quantity {
   font-size: 1.2rem;
   display: flex;
   align-items: center;

   > [aria-hidden] {
      display: none;
   }

   button {
      font-weight: bold;
      min-width: 3rem;
      color: #b00;
      border-color: currentColor;
      background: white;

      &:focus {
         outline: none;
      }

      &:hover,
      &:focus {
         background: #f5f5f5;
      }

      &:last-child {
         color: #088;
      }
   }

   .count {
      min-width: 2rem;
      text-align: center;
   }
}

.size {
   min-width: 4rem;
   min-height: 4rem;
   display: flex;
   align-items: center;
   justify-content: center;
}

.label {
   margin-right: 0.5rem;
}

.attrIcon {
   align-self: flex-start;
   height: 4rem;
}

.extra {
   flex: 1;
   display: flex;
   flex-flow: column;
   margin: 0;

   &:first-child {
      margin-right: 0.25rem;
   }

   &:last-child {
      margin-left: 0.25rem;
   }

   input {
      height: 32px;
      border: none;
      background: var(--controlBackground);
      border-radius: 3px;
      padding: 0 0.5rem;

      margin-top: 0.25rem;
   }
}

.dialogButtons {
   display: flex;
   margin-top: auto;
   padding-top: 1rem;

   .cancel {
      margin-right: auto;
   }

   > button {
      min-width: 7rem;
      font-weight: 600;
   }

   > * + * {
      margin-left: 0.5rem;
   }
}

.horizontal {
   display: flex;
   flex-flow: row wrap;

   > * {
      flex: 1;
   }
}

.wrapper {
   padding-left: 0;
   border-left: 1px solid #00000011;
   white-space: nowrap;
   flex: 1.2;
   overflow: auto;
   scrollbar-width: thin;
   position: relative;
}

.suggestions {
   display: flex;
   flex-flow: column;
   max-height: 0;

   hr {
      margin: 1rem;
   }

   h2 {
      font-size: 1.2rem;
      font-weight: normal;
      color: $accent;
      margin: 0;
      padding: 0 1rem;
   }

   ul {
      list-style: none;
      padding: 0.75rem 0 0;
      margin: 0;
      display: flex;
      flex-flow: column;
   }

   li {
      display: flex;
   }

   .pinResource {
      flex: 1;
      text-align: left;
      border: none;
      background: none;
      padding: 0.75rem 1rem;

      display: flex;
      align-items: center;

      &.unavailable {
         color: $red;
         text-decoration: line-through;
         font-weight: 600;
      }

      &:hover,
      &:focus-visible {
         background: #eee;
      }

      &:focus {
         outline: none;
      }

      span {
         flex: 1;
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
      }

      svg {
         margin-left: auto;
         min-width: 19px;
         height: 19px;
         stroke: black;
         fill: black;
         margin-left: 0.5rem;
         transform-origin: center;
         transform: rotate(-45deg);
         transition: transform 0.2s ease-out;

         &.outline {
            fill: transparent;
            transform: none;
            stroke: gray;
         }
      }
   }

   .loading {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: #ffffffcc;

      display: flex;
      align-items: center;
      justify-content: center;
   }
}

.spinner {
   height: 10rem;
   color: $accent;
   margin: auto;
}

.moreButton {
   margin-left: 1rem;
   margin-right: auto;
   margin-top: 1rem;
}
