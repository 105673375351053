@import './globals.module';
@import './time-grid-vars.module';

:export {
   cellHeight: $cell-height;
   cellWidth: $cell-width;
   headerHeight: $header-height;
}

.calendarContainer {
   display: flex;
   flex-flow: column;
   flex: 1;
   position: relative;
   min-height: 0;
}

.scrollContainer {
   flex: 1;
   overflow: auto;
}

.calendar {
   /* inline-flex + min-width makes sure we occupy at lest the whole width of the scroll-container while keepking width and height on 'auto' */
   display: inline-flex;
   min-width: 100%;

   background: transparentize($shadedBg, 0.5);
   position: relative;
}

$vertial-grid: linear-gradient(to right, $shadedBg 1px, transparent 1px) (0 0) / ($cell-width 100%) local;

$horizontal-grid:
   linear-gradient(to bottom, $shadedBg 1px, transparent 1px) (0 $header-height) / (100% $cell-height) local,
   linear-gradient(to bottom, transparentize($shadedBg, 0.5) 1px, transparent 1px) (0 $header-height + $cell-height / 2) / (100% $cell-height) local;

$horizontal-grid-no-header:
   linear-gradient(to bottom, $shadedBg 1px, transparent 1px) (0 0) / (100% $cell-height) local,
   linear-gradient(to bottom, transparentize($shadedBg, 0.5) 1px, transparent 1px) (0 0 + $cell-height / 2) / (100% $cell-height) local;

.column {
   display: flex;
   flex-flow: column;
   height: $rows * $cell-height + $header-height;
   flex: 0 0 auto;
   width: $cell-width;

   background: $horizontal-grid, $vertial-grid, white;

   .noHeaders & {
      height: $rows * $cell-height;
      background: $horizontal-grid-no-header, $vertial-grid, white;
   }

   header {
      background: $vertial-grid, $panelBackground;
      padding: 0.125rem 0.25rem;
      line-height: 1;
      font-size: 0.8rem;
      height: $header-height;
      overflow: hidden;

      position: sticky;
      top: 0;
      z-index: 1;

      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      span {
         overflow-wrap: break-word;
         min-width: 0;
      }

      .noHeaders & {
         display: none;
      }
   }

   &.filler {
      flex: 1 1;
      background: $vertial-grid no-repeat;
      width: 0;

      header {
         padding: 0;
         background: $vertial-grid no-repeat, $panelBackground;
      }
   }

   &.single {
      flex: 1 0 auto;
      width: auto;
      background: $horizontal-grid, $vertial-grid no-repeat, white;

      header {
         background: $vertial-grid no-repeat, $panelBackground;
      }

      .noHeaders & {
         background: $horizontal-grid-no-header, $vertial-grid no-repeat, white;
      }
   }

   &.loading {
      background-image: url('./loading.svg');
      background-size: 16px 16px;
   }
}

$time-hor-padding: 0.5rem;

.times {
   position: sticky;
   left: 0;
   font-size: 0.8rem;
   z-index: 1;
   width: $header-width;
   background: white;
   margin-top: $header-height;
   height: $rows * $cell-height;

   /* if line-height isn't large enough, the whole contents of the inline-flex moves down, use translate() to move numbers back up  */
   line-height: 2;
   .timeCell > div {
      transform: translate(0, -0.25rem);
   }

   .noHeaders & {
      margin-top: 0;
      background: white;
   }

   .timeCell {
      border-top: solid 1px $shadedBg;
      white-space: nowrap;
      min-height: $cell-height;
      text-align: right;
      padding: 0 $time-hor-padding;
   }
}

.cornerHeader {
   height: $header-height;
   width: $header-width;
   background: $panelBackground;
   position: absolute;
   top: 0;
   left: 0;
   z-index: 2;

   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;

   .noHeaders & {
      display: none;
   }
}

$current-time-color: #0eb;

.currentTimeLine {
   height: 1px;
   position: absolute;
   left: $header-width;
   right: 0;
   background: $current-time-color;
   margin-top: $header-height;
   box-shadow: 0 -2px 6px 1px transparentize($current-time-color, 0.625);
   pointer-events: none;

   .noHeaders & {
      margin-top: 0;
   }
}

.currentTime {
   position: absolute;
   right: 0;
   transform: translate(0, -50%);
   color: darken($current-time-color, 10%);
   font-size: 0.65rem;
   line-height: 1;
   margin-right: $time-hor-padding;
   background: white;
}

.currentTimeKnob {
   $size: 9px;

   position: absolute;
   right: 0;
   width: $size;
   height: $size;
   transform: translate(50%, -50%);
   background: $current-time-color;
   border-radius: 50%;
   border: solid 2px white;
   pointer-events: none;
}


.items {
   position: relative;
   flex: 1;
}

$hor-padding: 0.25rem;

.item {
   position: absolute;
   left: 0;
   right: 0;
   font-size: 0.7rem;
   padding: 0.0625rem $hor-padding;
   border-top: solid 1px $accentedForeground;
   line-height: 1;
   overflow: hidden;
}
