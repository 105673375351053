@import '../globals.module';

.selector {
   border: none;
   background: none;
   display: flex;
   padding: 0;
   outline: none;
   color: black;
   min-width: 0;

   &:focus {
      outline: none;
   }
}

.multiSelect {
   justify-content: space-between;
   align-items: center;
}

.multiSelectItem {
   padding: 0.5rem 1rem;
   display: flex;
   align-items: center;

   border: none;
   background: none;
   outline: none;

   &:focus {
      outline: none;
   }

   .check {
      margin-left: auto;
      color: $accent;
   }
}

.singleSelect {
   justify-content: space-between;
   align-items: center;
}

.singleSelectItem {
   padding: 0.5rem 1rem;
   display: flex;
   align-items: center;

   border: none;
   background: none;
   outline: none;

   &:focus {
      outline: none;
   }

   .radio {
      margin-left: auto;
      color: $accent;
   }
}

.label {
   padding-right: 1rem;
}

.items {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}
