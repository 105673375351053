.dialogButtons {
   margin-top: 1rem;
   display: flex;
   justify-content: flex-end;

   > button {
      min-width: 5rem;
   }
}

.cancelButton {
   margin-left: 1rem;
   background: none;
   border-width: 2px;
   color: inherit;
}
