.Version {
   position: absolute;
   left: 0;
   bottom: 0;
   z-index: 999999;
   pointer-events: none;
   color: white;
   margin: 0.25rem;
   text-shadow: 1px 1px 1px #000;
   opacity: 0.75;
}
