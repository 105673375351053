@import './globals.module';

.body {
   background: white;

   h2 {
      font-size: 1.1rem;
      font-weight: 600;
      color: $accent;
   }

   section {
      &:not(:first-child) {
         margin-top: 0.5rem;
      }
   }
}

.resources {
   margin: 0;
   list-style: none;
   padding-left: 0.5rem;

   li {
      font-weight: 600;
   }
}

.room {
   display: inline-flex;
   align-items: center;
}

.buttons {
   display: flex;
   justify-content: flex-end;
   margin-top: 1rem;

   > * + * {
      margin-left: 0.5rem;
      height: 3rem;
      min-width: 6rem;
   }
}

.updateMeeting {
   display: flex;
   align-items: center;
}

.spinner {
   height: 1.5rem;
   margin-right: 0.5rem;
}

.error {
   color: $red;
   margin-top: 1rem;
}

.roomIcon {
   margin-right: 0.25rem;
}

.warning {
   margin-top: 0.5rem;
   line-height: 1.1;

   span {
      color: orange;
      font-size: 2.5rem;
      padding-right: 0.5rem;
   }
}
