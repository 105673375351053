@import '../globals.module';
@import '../time-grid-vars.module';

.selectionContainer {
   position: absolute;
   pointer-events: none;
   transition: transform 0.33s cubic-bezier(0.2, 1, 0.1, 1);
}

.selection {
   background: transparentize($accent, 0.2);

   touch-action: none;
   cursor: grab;
   pointer-events: initial;

   font-size: 0.8rem;
   color: $accentedForeground;
   padding: 0 0.25rem;

   .arrow {
      opacity: 0.5;
      margin: 0 0.25rem;
   }

   .duration {
      display: block;
   }

   &.compact, &.overflow {
      .duration {
         display: inline;
         margin-left: 0.5rem;
      }
   }

   &.overflow {
      .text {
         color: black;
         position: relative;
         top: -1.25rem;
      }
   }
}

.text {
   pointer-events: none;
}

@mixin knob {
   position: absolute;
   cursor: n-resize;
   width: 2rem;
   height: 2rem;

   &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: white;
      border-radius: 50%;
      border: solid 2px $accent;
      margin: 0.5rem;
   }
}

.knobTop {
   @include knob();
   top: 0px;
   transform: translate(0, -50%);
   right: 0;
}

.knobBottom {
   @include knob();
   bottom: 0px;
   transform: translate(0, 50%);
   left: 0;
}
