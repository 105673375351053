@import './globals.module.scss';

@mixin background($color, $disabled-color, $border: null) {
   &:hover {
      background-color: $color;
      border-color: $border or $color;
   }

   &:active:not(:disabled) {
      background-color: scale-color($color, $lightness: -10%);
      border-color: scale-color($border or $color, $lightness: -10%);
   }

   &:disabled {
      background-color: rgba($color, 0.5);
      border-color: transparent;
      color: $disabled-color;
   }
}

.button {
   font-weight: 600;
   border-width: 1px;
   border-style: solid;
   border-radius: 3px;
   padding: 0.5rem 1rem;
   color: #242424;

   &:not(.none) {
      border-color: #d1d1d1;
      background: white;
      @include background(#f5f5f5, #b8b6b4, #bdbdbd);
   }

   display: flex;
   align-items: center;
   justify-content: center;

   &:focus {
      outline: none;
   }

   &:focus-visible {
      outline: 1px dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -5px;
   }

   &.accent {
      background-color: $accent-light;
      border-color: $accent-light;
      @include background($accent, #e8e6e4);
      color: white;
   }

   &.red {
      background-color: $red;
      border-color: $red;
      @include background(#d22525, #e8e6e4);
      color: white;
   }

   &.green {
      background-color: $green;
      border-color: $green;
      @include background(#157949, #e8e6e4);
      color: white;
   }
}
