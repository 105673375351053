@import './globals.module';

.accentButton {
   border-color: $accent;
   background: $accent;
   color: $accentedForeground;
   &:hover, &:active:not(:disabled) {
      background: darken($accent, 10%);
      border-color: darken($accent, 10%);
   }
}
