@import '../globals.module';

.loadMeeting {
   padding: 1rem;
   display: flex;
   flex: 1;
}

.error {
   color: $red;
}

.spinner {
   margin: auto;
   color: $accent;
   width: 30vmin;
}
