.Pager {
   display: flex;
   flex-flow: column;
}

.container {
   display: flex;
   flex-flow: row;
   overflow: hidden;
   margin-top: 2rem;
   flex: 1;
   position: relative;
}

.child {
   display: flex;
   flex-flow: column;
   overflow: hidden;
   position: absolute;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   transition: transform 200ms ease-out;

   &.enter {
      transform: translateX(100vw);
   }

   &.exit {
      transform: translateX(-100vw);
   }

   &.active {
      transform: none;
   }
}

.measurer {
   visibility: hidden;
}

.pages {
   height: 2rem;

   display: flex;
   align-items: center;
   justify-content: center;

   .page {
      background: #ffffff11;
      width: 2.75rem;
      height: 0.8rem;
      border-radius: 0.2rem;

      &.active {
         background: #fff;
      }

      & + * {
         margin-left: 0.5rem;
      }
   }
}
