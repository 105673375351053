@import '../globals.module';

.error {
   color: red;
}

.logo {
   height: 2rem;
}

.spinner {
   margin-left: 0.5rem;
   height: 24px;
}

.row {
   display: flex;
}

.input {
   flex: 1;
}

.otpStatus {
   display: flex;
   flex-flow: column;

   .message {
      padding: 0.5rem 1rem;
      padding-left: 3rem;
      margin: 0;
   
      &.error {
         color: $red;
      }
   }

   .waiting {
      padding: 0.5rem 1rem;
      display: flex;
      margin: 0;
   
      svg {
         height: 1.5rem;
         margin-right: 0.5rem;
      }
   }
}

.meetingLinkInfo {
   padding: 1rem;
   padding-bottom: 0;
}
