@import '../globals.module';

.externalUserView {
   padding: 1rem;
   font-size: 1.5rem;

}

.myCalendar {
   flex: 1;
   display: flex;
   flex-flow: column;
   min-height: 0;
}

.header {
   display: flex;
   flex-flow: row nowrap;
   align-items: center;
   padding: 1rem 0;

   .month {
      margin: 0 1rem;
      flex: 1;
   }
}

.subHeader {
   color: black;
   background: rgb(233, 233, 233);
   height: 4rem;
}

.timeGrid {
   .column {
      flex: 1;
      background-repeat: repeat-y;

      &.loading {
         background-repeat: repeat;
      }
   }
}

.meeting {
   background: #e1e1e1;
   color: #6b6b6b;

   &.clickable {
      cursor: pointer;
      background: transparentize($accent-light, 0.8);
      color: $accent;
      border-left: 3px solid $accent-light;
   }
}

.datePicker {
   display: flex;
   box-shadow: 0 3px 3px #00000022;
   z-index: 9;

   overflow: auto;

   // Safari and Chrome
   &::-webkit-scrollbar {
      display: none;
   }

   // FireFox
   scrollbar-width: none;

   .week {
      flex: 0 0 100vw;
      display: flex;
   }

   .day {
      flex: 1;

      border: none;
      background: none;
      padding: 0;

      &:focus {
         outline: none;
      }

      display: flex;
      flex-flow: column;
      align-items: stretch;
   }

   .weekDay {
      background: $accent-light;
      color: white;
      font-weight: 600;
      padding: 0.5rem 0.25rem;
   }

   .bottom {
      display: grid;
      grid-template: 11px 1fr 11px / auto;
      align-items: center;
      justify-items: center;
      padding: 0.25rem;
      position: relative;
      color: #666;

      &::before {
         content: '';
         position: absolute;
         left: 0;
         right: 0;
         bottom: 0;
         top: 0;
         margin: auto;

         border-radius: 50%;
         width: 2rem;
         height: 2rem;
      }
   }

   .month,
   .year {
      font-size: 11px;
      line-height: 1;
      overflow: visible;
   }

   .nonCurrent {
      background: #f1f2f3;
   }

   .dayOfMonth {
      grid-area: 2 / 1 / 3 / 2;
      z-index: 1;

      display: flex;
      align-items: center;
      justify-content: center;
   }

   .today .bottom {
      .dayOfMonth {
         font-weight: 600;
         color: $accent;
      }

      &::before {
         background: transparentize($accent-light, 0.8);
      }
   }

   .active .bottom {
      .dayOfMonth {
         color: white;
         font-weight: 600;
      }

      &::before {
         background: $accent-light;
      }
   }

   .weekend {
      color: transparentize(white, 0.5);
   }
}
