@import './globals.module';

.teamsDropdown {
   position: relative;
}

.toggle {
   display: flex;
   align-items: center;
   line-height: 0;
   height: 2.5rem;
   background: none;
   border: none;
   color: $accent;

   // caret
   &::after {
      content: '';
      display: inline-block;
      margin-left: 0.5em;
      vertical-align: 0.255em;
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
   }
}

.autoComplete {
   position: absolute;
   background: white;
   z-index: 9;
   display: flex;
   flex-flow: column;

   min-width: 10rem;
   min-height: 2rem;
   margin-top: 1px;

   &.right {
      right: 0;
   }

   border: 1px solid rgba(0, 0, 0, 0.15);
   border-radius: 0.25rem;

   input {
      flex: 1;
      margin: 0.5rem;
      border: none;
      border-bottom: 1px solid black;
      font-size: inherit;
      font-family: inherit;
   }
}

.menu {
   position: static;
   box-shadow: none;

   > div {
      display: flex;
      flex-flow: column;
      overflow-y: scroll;
   }
}

.active {
   background: #f8f9fa;
}

.team {
   display: flex;
   white-space: nowrap;
   padding: 0.5rem;

   .title {
      flex: 1 auto;
   }

   .members {
      color: auto;
      margin-left: 1rem;
      display: flex;
      align-items: center;
   }

   .member {
      height: 24px;
      width: 24px;
      min-width: 0;
      margin-left: -2px;
   }

   .moreMembers {
      border-radius: 50%;
      background: gray;
      color: white;
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
   }
}
