@import './globals.module';

.externalUserView {
   padding: 1rem;
   font-size: 1.5rem;

}
.myCalendar {
   flex: 1;
   display: flex;
   flex-flow: column;
   min-height: 0;

   > header {
      display: flex;
      color: $accentedForeground;
      background: $accent;
      justify-content: flex-end;
      align-items: center;
      padding: 0.5rem 1.5rem;
      padding-right: 0;
      position: relative;

      line-height: 1rem;
      font-size: 1.5rem;
      font-weight: 600;

      > .button {
         cursor: pointer;
         display: flex;
         flex-flow: column;
         align-items: center;
         font-size: 0.8rem;
         min-width: 5rem;

         svg {
            height: 2rem;
         }
      }

   }

   &.teamsMode > header {
      background: #ddd;
      color: #444;
      font-weight: normal;
      font-size: 1.2rem;
   }

   .columnHeader {
      background: #b1b1b1 !important;
      justify-content: center;
      border-left: 2px solid #ddd;
   }
}

.myself {
   display: flex;
   align-items: center;
}

.photo {
   color: inherit;
}

.date {
   margin: auto;
   text-align: center;
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
}

.meeting {
   background: $accent;
   color: $accentedForeground;
   cursor: pointer;

   &.long {
      background: lighten($accent, 20%);
   }
}

.today {
   position: absolute;
   left: 0;
   right: -2px;
   top: 0;
   bottom: 0;
   background: $accent;
   opacity: 0.25;
   pointer-events: none;
}
