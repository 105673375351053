.DisplayBoard {
   height: 100vh;

   background: #121b27;
   color: white;

   display: flex;
   flex-flow: column;

   padding: 1rem;

   h1 {
      font-size: 2rem;
      font-weight: normal;
      align-self: flex-start;
      border-bottom: 0.15rem solid #ffffff44;
   }

   .time {
      font-size: 3rem;
      line-height: 1;
      font-weight: 300;
   }

   .content {
      display: flex;
      flex-flow: column;
      flex: 1;

      @media all and (orientation: landscape) {
         flex-flow: row;
      }
   }

   .rooms {
      flex: 1;
   }

   .map {
      @media all and (orientation: landscape) {
         margin-left: 2rem;
         flex: 0 40%;
         align-self: center;
      }
   }

   .logo {
      position: absolute;
      top: 1rem;
      right: 1rem;
      height: 7rem;
   }

   .notification {
      flex: 1;
      font-size: 3rem;
      padding: 1rem;
      font-weight: 300;

      display: flex;
      flex-flow: column;
      justify-content: center;

      .name {
         text-align: center;
      }

      em {
         font-style: normal;
         font-weight: 600;
      }

      > div {
         margin: 1rem;
      }
   }
}
