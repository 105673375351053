@import '../globals.module';

.floatingActionButton {
   position: fixed;
   right: 0;
   bottom: 0;

   border: none;
   background: $accent-light;
   color: white;

   &:focus {
      outline: none;
   }

   width: 56px;
   height: 56px;
   margin: 16px;
   padding: 16px;
   border-radius: 50%;

   box-shadow: 0 2px 4px #00000088;

   > svg {
      height: 24px;
      width: 24px;
   }
}
