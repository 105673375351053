@import './globals.module';

.meetingView {
   display: flex;
   flex-flow: column;

   font-size: 1.2rem;
}

.titleRow {
   display: flex;
   flex-flow: row;
   margin-bottom: 1rem;
}

.row {
   display: flex;
   flex-flow: row;

   margin-bottom: 0.5rem;
}

.title {
   font-size: 2rem;
   color: $accent;
   line-height: 2.5rem;
}

.wrap {
   flex: 1;
   min-width: 0;
   overflow-wrap: break-word; // to break the long _______ thing
}

.headerColumn {
   display: flex;
   flex-flow: column;
   justify-content: center;
   align-items: center;
   min-width: 2.5rem;
   margin-right: 1rem;
}

.icon {
   font-size: 1.5rem;
   color: $accent;
}
