@import './globals.module';

.timeline {
   flex: 1 1 auto;

   display: flex;
   flex-flow: column;
   align-items: flex-start;
   padding: 3rem 0 5rem;

   // fixed background glitches in Chrome, using this instead
   &:before {
      content: '';
      position: fixed;
      z-index: -1;
      top: 0;
      width: 100%;
      height: 100%;
      background: #444 var(--bg) center/cover;
   }

   .spinner {
      align-self: center;
      margin: auto 0;

      .svg {
         height: 200px;
         color: white;
      }
   }
}
