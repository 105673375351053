@import '../globals.module';

.addResources {
   > header {
      margin: 0 1rem;
      padding: 1rem 0 0;
      font-weight: 600;
      color: $accent;
      border-top: 1px solid #efefef;
   }
}

.warning {
   color: orange;
}

.title {
   :global(.fa) {
      font-size: 0.8rem;
   }

   div {
      display: flex;
      align-items: center;
   }
}

.roomIcon {
   width: 24px;
   height: 24px;
}
