$padding: 1rem;

.pinInput {
   display: flex;
   font-size: 4rem;
   margin-right: -$padding;
}

.item {
   margin-right: $padding;
   background-color: white;

   flex: 0 0 auto;
   width: 5rem;
   height: 5rem;
   line-height: 1;

   text-align: center;

   &:nth-child(3) {
      margin-right: 2 * $padding;
   }

   &:last-child {
      margin-right: 0;
   }
}

.hiddenInput {
   position: absolute;
   width: 0;
   height: 0;
   border: none;
   padding: 0;
}
