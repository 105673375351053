@import './components/globals.module';

html {
   height: 100%;
   font-size: 14px;
   overflow: hidden;
}

body {
   line-height: normal;
   height: 100%;
   --controlBackground: #eee;
}

:global(#root) {
   height: 100%; 
}

.app {
   color: $foreground;
   height: 100%;
   user-select: none;

   display: flex;
   flex-flow: column;

   header,
   footer {
      flex: 0 0 auto;
   }

   main {
      flex: 1 1 auto;
      overflow-y: auto;
      position: relative; // to make <main> offsetParent, because it controls scrolling. need this for Autocomplete popups

      display: flex;
      flex-flow: column;
   }
}

.popperHost {
   position: absolute;
   z-index: 9999;
}

svg {
   fill: currentColor;
}

:global {
   .modal {
      text-align: center;
   }

   .modal-dialog {
      text-align: left;
   }

   .modal-content {
      background-color: transparent;
   }

   .modal-header {
      background: $accent;
      color: $accentedForeground;
   }

   .modal-body {
      background-color: #e1e1e1;
      border-bottom-left-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
   }

   .input-group-text .fa {
      width: 16px;
   }

   .react-datepicker {
      font-size: 0.9rem;
      border-radius: 0;
      border: none;
      display: flex;
   }

   .react-datepicker__header {
      border-radius: 0;
      background: white;
      border-bottom-color: #e0e0e0;
   }

   .react-datepicker__day-name,
   .react-datepicker__day,
   .react-datepicker__time-name {
      width: 2rem;
      height: 2rem;
      line-height: 2.1rem;
   }

   .react-datepicker__day--selected {
      background-color: $accent;

      &:hover {
         background-color: scale-color($accent, $lightness: +10%);
      }
   }
}

* {
   outline: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

input[type='number'] {
   -moz-appearance: textfield;
}

.spinner {
   position: fixed;
   left: 0;
   top: 0;
   bottom: 0;
   right: 0;
   margin: auto;
   width: 30vmin;
   color: $accent;
}
