.page {
   height: 100vh;
   overflow-y: auto;

   h2 {
      padding: 1rem;
      margin: 0;
      font-size: 1.4rem;
      font-weight: normal;
   }

   em {
      font-style: normal;
      font-weight: 600;
   }

   p {
      padding: 1rem;
      font-size: 1.2rem;
   }
}
