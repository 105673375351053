.formField {
   display: flex;
   margin: 0;

   .icon {
      width: 2rem;
      margin-right: 1rem;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
         height: 1.5rem;
      }
   }

   .body {
      flex: 1;
      display: flex;
      align-items: center;
   }

   & + & {
      margin-top: 1rem;
   }
}
