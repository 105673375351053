@import '../globals.module';

.showMore {
   border: none;
   background: none;
   outline: none;
   padding: 1rem;

   &:focus {
      outline: none;
   }

   flex: 0;
   white-space: nowrap;
   text-transform: uppercase;
   color: $accent-light;
   font-weight: 600;
   margin-left: auto;
}

.wrapper {
   padding: 0.5rem;
   flex: 1;
   display: flex;
   flex-flow: column;
}

.spinner {
   margin: 0 auto;
   width: 20vmin;
   color: $accent;
}

.block {
   border: none;
   border-radius: 2px;
   background: none;
   padding: 0.5rem;
   box-shadow: 0 1px 3px #00000044;
   text-align: left;

   &:focus {
      outline: none;
   }

   & + & {
      margin-top: 0.5rem;
   }
}

.header {
   color: $accent;
   font-weight: 600;
}

.error {
   color: $red;
   font-weight: 600;
}
