@import '../globals.module';


.bookingSuccess {
   display: flex;
   flex-flow: column;
   align-items: center;
   color: $accent;
   padding: 2rem 1rem;

   h1, h2 {
      margin: 0;
      font-weight: normal;
   }

   h1 {
      font-size: 1.5rem;
   }

   svg {
      height: 70vmin;
      margin: 2rem 0;
   }

   h2 {
      font-size: 1rem;
   }
}
