.resourceIcon {
   font-weight: 400;
   font-size: 11px;

   > div {
      position: relative;
      width: 100%;
      height: 100%;
   }

   svg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
   }
}
