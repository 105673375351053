@import '../globals.module';

@mixin button {
   border: none;
   background: none;
   padding: 0;
   text-align: left;

   &:focus {
      outline: none;
   }
}

.clock,
.timePart {
   @include button;
}

.datePart,
.timePart,
.datePicker {
   @include button;
   display: flex;
   flex-flow: column;

   > span {
      font-size: 0.9rem;
      color: #666;
   }
}

.dateTimeField .datePicker {
   margin: 1rem 0;
}

.divider {
   flex: 0;
   margin: 0.5rem 1rem;
   min-width: 1px;
   background: #efefef;
}

.datePart {
   flex: 0;
   white-space: nowrap;
}

.timePart {
   flex: 0;
   white-space: nowrap;
}

.arrow {
   align-self: center;
   flex: 0;
   opacity: 0.5;
   margin: 0 0.5rem;
}

.header {
   display: flex;
   flex-flow: row nowrap;
   align-items: center;

   .month {
      flex: 1;
   }

   .clock {
      padding: 1rem;
      margin: -1rem 0;
      margin-left: 1rem;
      margin-right: -0.5rem;
      color: white;

      > svg {
         height: 2rem;
      }
   }
}

.freeBusyPanel {
   display: flex;
   border-top: 1px solid #ddd;
   overflow-x: auto;

   .wrapper {
      display: flex;
      padding: 0.5rem;

      > * {
         border: 3px solid #778;
         flex: 0 auto;

         & + * {
            margin-left: 0.25rem;
         }
      }
   }

   .optional {
      border-style: dotted;
   }

   .free {
      border-color: $green;
   }

   .busy {
      border-color: $red;
   }
}

.durationPicker {
   display: flex;
   box-shadow: 0 3px 3px #00000022;
   background: white;
   z-index: 10;
   padding: 0.25rem;
   border-top: 1px solid #ddd;
   overflow: auto;

   > button {
      flex: 1;
      @include button;
      text-align: center;
      padding: 0.5rem;
      margin: 0.25rem;
      background-color: transparentize($accent, 0.8);
   }

   .active {
      color: white;
      background-color: $accent;
      font-weight: 600;
   }
}

.timePicker {
   text-align: right;
}

.simple {
   display: grid;
   grid-template: auto auto / auto 1fr;
   gap: 0.5rem;
   align-items: center;
}

.durationButton {
   > div:first-child {
      font-size: 0.8rem;
   }

   > div:last-child {
      white-space: nowrap;
   }
}
