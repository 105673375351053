@import '../globals.module';

.header {
   display: flex;
   background: $accent-light;
   color: white;
   font-size: 1.5rem;
   font-weight: 300;
   align-items: center;
   min-height: 3.5rem;

   > button {
      border: none;
      background: none;
      outline: none;
      color: inherit;
      padding: 1rem;
      overflow: visible;

      position: relative;

      span {
         width: 21px;
      }

      &:active::before {
         animation: 100ms ease-out touch;
      }

      &::before {
         content: '';
         position: absolute;
         width: 50px;
         height: 50px;
         left: 0; top: 0; bottom: 0; right: 0;
         margin: auto;
         border-radius: 50%;
         background: radial-gradient(transparent, #ffffff88);
         transform: scale(0);
      }

      @keyframes touch {
         0% {
            transform: scale(0);
         }
         100% {
            transform: scale(2);
         }
      }
   }

   > :first-child {
      margin-left: 0.5rem;
   }

   > :last-child {
      margin-right: 0.5rem;
   }

   .content {
      flex: 1;
      padding: 0 0.5rem;
   }
}
